<template>
    <div class="scan-page" v-if="device">

        <!-- Initial Page -->
        <template v-if="!serialNumberEntered">

            <!-- Serial Number Input -->
            <div class="container repair-container">
                <div class="row">
                    <div class="col-12">
                        <label>Enter device serial number</label>
                    </div>
                    <div class="col">
                        <input type="text" class="form-control" v-model="device.serial_number">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-success" @click="validateSerialNumber()">Add</button>
                    </div>
                </div>
            </div>

            <!-- Validating overlay -->
            <loading-state
                    v-if="validating"
                    overlay
                    title="Validating serial number">
            </loading-state>
        </template>

        <!-- Valid Serial Number -->
        <template v-if="serialNumberValid && serialNumberEntered">
            <div class="container repair-container">
                <empty-state
                        statusIcon="success"
                        :title="`The serial number <strong>${device.serial_number}</strong> is valid for this device`">
                </empty-state>
                <div class="row">
                    <div class="col-6 offset-md-3">
                        <button class="btn btn-block btn-info" @click="clearSerialNumber()">Re-enter serial number</button>
                    </div>
                </div>
            </div>
        </template>

        <!-- Invalid Serial Number -->
        <template v-if="!serialNumberValid && serialNumberEntered">
            <div class="container repair-container">
                <empty-state
                        statusIcon="error"
                        :title="`The serial number <strong>${device.serial_number}</strong> is not valid for this device`"
                        subtitle="Click the button below to re-enter the serial number or contact your SSM if you require more help">
                </empty-state>
                <div class="row">
<!--                    <div class="col-6">-->
<!--                        <button class="btn btn-block btn-danger btn-sm">Serial number mismatch</button>-->
<!--                    </div>-->
                    <div class="col-6 offset-md-3">
                        <button class="btn btn-block btn-info" @click="clearSerialNumber()">Re-enter serial number</button>
                    </div>
                </div>
            </div>
        </template>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': footerActive}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName"
                :prevRoute="this.prevRepairStep($route.name).routeName">
        </fixed-footer>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {

        props:["jobId", "deviceId"],

        data() {
            return {
                serialNumberEntered: false,
                serialNumberValid: false,
                validating: false,
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });
        },
        
        computed: {
            ...mapGetters([
                "device",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep"
            ]),

            /** Check if footer should be active */
            footerActive() {
                if (this.device.serial_number && this.serialNumberValid || this.isJobManager) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapActions([
                "clearSerialNumberEntered",
                "displayToast",
                "loadDeviceForJob",
                "saveSerialNumber",
                "setCurrentStep"
            ]),

            /** Clear Serial Number */
            clearSerialNumber() {
                this.serialNumber = null;
                this.serialNumberEntered = false;
                this.serialNumberValid = false;
                this.clearSerialNumberEntered({
                    jobId: this.jobId,
                    deviceId: this.deviceId
                });
            },

            /** Validate Serial Number */
            validateSerialNumber() {
                if (this.validating) return;

                this.validating = true;

                this.saveSerialNumber({
                    deviceId: this.deviceId,
                    serialNumber: this.device.serial_number
                }).then(() => {
                    this.showFooter = true;
                    this.validating = false;
                    this.serialNumberValid = true;
                    this.serialNumberEntered = true;
                }).catch(() => {
                    this.showFooter = true;
                    this.validating = false;
                    this.serialNumberEntered = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>